import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AnimatedPath, Side } from "../curveGenerator";
import { TextFallIn } from "./animations";

const HEIGHT: number = 120;
const NOISE_SCALE: number = 12;
const TIME_SCALE: number = 0.00015;

const Container = styled.div`
  width: 100vw;
  height: ${HEIGHT}px;
  //
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background: none;
`;

const PathContainer = styled.svg`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: ${HEIGHT + NOISE_SCALE}px;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, .6));

  & > path {
    fill: ${props => props.theme.accent};
    stroke: none;
  }
`;

const Header = styled.div`
  z-index: 2;
  font-family: Ubuntu, sans-serif;
  font-size: 40px;
  color: ${props => props.theme.text.light};
  text-transform: uppercase;
  margin-top: 10px;
  //
  opacity: 0;
  animation: ${TextFallIn} .8s ease-out forwards;
  animation-delay: 0.45s;
`;

const Nav = styled.div`
  z-index: 2;
  display: flex;
  flex-flow: row wrap;
  font-family: Lato, sans-serif;
  font-size: 20px;
  color: ${props => props.theme.text.light};
  //
  opacity: 0;
  animation: ${TextFallIn} .8s ease-out forwards;
  animation-delay: 0.65s;
`

const NavLink = styled(Link)<{ selected: boolean }>`
  text-decoration: none;
  color: inherit;
  font-family: inherit;
  display: block;
  transition: transform .1s ease-in-out;
  margin: 10px 10px 0;
  line-height: 1.2;
  border-bottom: ${props => props.selected ? "2px solid rgba(255, 255, 255, 0.6)" : "none"};

  :hover {
    transform: translateY(-2px);
  }
`

export const TopBar: FC = (props) => {
  const animationFrame = React.useRef<number>();
  const [path, setPath] = useState("");
  const [pathTracker] = useState(new AnimatedPath(Side.BOTTOM, TIME_SCALE, NOISE_SCALE, "andrew"));

  const animate = (time: number) => {
    setPath(pathTracker.renderToPathString(time, HEIGHT, window.innerHeight));
    animationFrame.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    animationFrame.current = requestAnimationFrame(animate);
    return () => {
      if (animationFrame.current) cancelAnimationFrame(animationFrame.current);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const location = useLocation();
  const links = {
    "/about": "About Me",
    "/resume": "Resume",
    "/projects": "Projects",
  };

  return (
    <Container {...props} >
      <Header>Andrew Gies</Header>
      <Nav>
        {Object.entries(links).map(([path, text]) => {
          let selected = location.pathname.startsWith(path);
          return <NavLink selected={selected} to={path} key={path}>{text}</NavLink>;
        })}
      </Nav>
      <PathContainer {...props}>
        <path d={path} />
      </PathContainer>
    </Container>
  );
};
