import React from "react";
import styled from "styled-components";
import { ContentContainer } from "../components/ContentContainer";

const SectionHeader = styled.div`
  font-family: Lato, sans-serif;
  font-size: 2.25rem;
  color: #000000;
  margin: 2rem 0 0;
`;

const EducationItem = styled.div`
  font-family: Average Sans, sans-serif;
  font-size: 1.3rem;
  color: #000000;
  margin: 0 0 0.85rem 40px;
`;

const SubHeader = styled.div`
  font-family: Average Sans, sans-serif;
  font-size: 1.75rem;
  color: #000000;
  margin: 0.85rem 0 0 20px;
`;

const ExtraInfo = styled.div`
  font-family: Average Sans, sans-serif;
  font-size: 1rem;
  color: #4D4D4D;
  margin: 0.1rem 0 0.5rem;
`;

const Description = styled.div`
  font-family: Average Sans, sans-serif;
  font-size: 1rem;
  color: #000000;
  margin: 0.1rem 0 0.5rem 40px;
`;

export const Resume = () => {
  return (
    <ContentContainer>
      <SectionHeader>Education</SectionHeader>
      <SubHeader>University of Washington</SubHeader>
      <EducationItem>
        BS, Computer Science &middot; Expected Completion Spring 2021
        <ExtraInfo>Focus on fundamentals and theory. Advanced work in hardware/software systems and software design.</ExtraInfo>
      </EducationItem>
      <EducationItem>
        BA, Drama Design &middot; Expected Completion Spring 2021
        <ExtraInfo>Study in theatrical design and theatre technologies, especially lighting and sound control systems.</ExtraInfo>
      </EducationItem>
      <EducationItem>
        MS, Computer Science &middot; Expected Completion Spring 2023
        <ExtraInfo>Combined BS + MS program, expected to begin MS component in Spring 2022.</ExtraInfo>
      </EducationItem>
      <SectionHeader>Experience</SectionHeader>
      <SubHeader>Axiom Law</SubHeader>
      <Description>
        <ExtraInfo>Software Engineering Intern &middot; Summer 2020</ExtraInfo>
        Full-stack JavaScript - developing internal and external web-based tools. Upgraded years worth of libraries and
        external dependencies, and overhauled the majority of the codebase to handle the upgrades. Streamlined and fixed
        issues with our CI infrastructure.
      </Description>
      <SubHeader>University of Washington</SubHeader>
      <Description>
        <ExtraInfo>
          Computer Science Teaching Assistant &middot; April 2019 - Present<br />
          CSE 331 (Software Design) Infrastructure Lead &middot; June 2019 - Present
        </ExtraInfo>
        Teaching section and assisting in new curriculum and assignment development. Designed and gave lectures and
        assignments for a new course segment on React.js. Also developed a new suite of course management software (see
        Courseware Development, below).
      </Description>
      <SubHeader>DubHacks</SubHeader>
      <Description>
        <ExtraInfo>
          Tech Team Member &middot; January 2020 - Present<br />
          Tech Team Director &middot; December 2020 - Present
        </ExtraInfo>
        Member of a small team managing backend infrastructure and website development for supporting multiple
        large-scale hackathons, including applications and admissions, judging, and participant management. Designed and
        developed the Reality system (see below) as part of this role.
      </Description>
      <SectionHeader>Projects</SectionHeader>
      <SubHeader>Reality</SubHeader>
      <Description>
        <ExtraInfo>Full-Stack TypeScript - Node + React, Google Cloud, MongoDB</ExtraInfo>
        Designed and built a distributed, scalable engine for multiplayer in-browser games. Designed to allow
        extensibility for the development of a wide variety of real-time multiplayer experiences that can automatically
        scale to support hundreds or thousands of concurrent players. Used to build an interactive community space for
        participants of DubHacks’ virtual hackathon events during 2020.
      </Description>
      <SubHeader>UW CSE Courseware Development</SubHeader>
      <Description>
        <ExtraInfo>Python 3, Java, Gradle, GitLab CI</ExtraInfo>
        Redesigned and rewrote a large suite of course management software responsible for autograding, assignment
        submission, and repository management. Focus on reusability, configurability, and modularity, since course
        lecturers (and therefore policies) change often.
      </Description>
      <SubHeader>Vines</SubHeader>
      <Description>
        <ExtraInfo>C, Arduino, Hardware Control + Development</ExtraInfo>
        A custom special effects system managing more than 2000 individually-addressed three-color LEDs during a
        musical theatre production. Consisted of hardware construction and integration, as well as a custom control
        system built for a very speed- and memory-constrained microcontroller.
      </Description>
    </ContentContainer>
  );
};
