import React from "react";
import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  box-shadow: 0 0 1px black;
  border-radius: 3px;
`;

const Video = styled.video`
  width: 100%;
  box-shadow: 0 0 1px black;
  border-radius: 3px;
`;

const Quote = styled.div`
  border-left: 1px solid grey;
  padding-left: 15px;
`;

export const ProjectVines = () => {
  return (
    <>
      <h2>Introduction</h2>
      <p>
        I love theatre, so much so that I'm actually getting a degree in it in addition to my Computer Science degree.
        I've been involved in the technical side of theatre since middle school, and I got a job working as a
        technician for a theater management company almost the moment I turned 18. At the time of this story, I
        already had a few years of experience working with more traditional theater technologies like lighting and
        sound equipment.
      </p>
      <p>
        I also love tinkering. I've had a large arduino/hobby electronics kit for years, and before that I had a
        "Snap Circuits" kit as a kid. This is the story of the time I got to combine these two loves, when I was
        working on a production of The Little Shop of Horrors.
      </p>
      <h2>The Backstory</h2>
      <p>
        This was about halfway through my freshman year of college. I was (and am) a member of a student
        organization called Stage Notes that produces musicals a few times a year. Little Shop was going to be
        opening to audiences in mid-May, and the director and assistant director were beginning to choose their
        production team: set designers, lighting and sound designers, vocal and pit directors, the
        choreographer, and the technical director. I wanted to be the Technical Director. The Technical
        Director's job is to manage all the technical elements of the show: if it goes onstage or is visible to
        the audience, and it's not alive, it's ultimately the Technical Director's responsibility.
      </p>
      <p>
        During the application process, a number of us received emails from the director offering us a
        challenge. He and the assistant director wanted to use our responses to the challenge to help them
        select the production team members. Here's the email I received in November:
      </p>
      <Quote>
        <span>Dear all,</span>
        <p>
          Thank you for applying for production team positions for Little Shop of Horrors. We were impressed
          and intrigued by your answers in the application form and we would like to move forward with the
          selection process with you in mind. In an effort to find the person(s) most suited for each
          position, we are issuing a challenge. Your response to the challenge, which is detailed below, will
          give us a better idea of your problem solving skills as well as your technical knowledge. As you
          develop your response to the problem, keep in mind that the ultimate goal of any stage production is
          to tell a compelling story. As such, any technical decision that you make should be a means to that
          end. Please do not think too hard about your responses; we aren’t looking for anything mind-blowing.
          The purpose of this exercise is to get a glimpse of your thought process and your interest level in
          this show. Do your best to stay within the constraints that I’ve outline below, but most
          importantly, be creative and have fun.
        </p>
        <p>
          <strong>Context:</strong> At the end of Little Shop of Horrors, the man-eating plant, Audrey II gets
          everything it wants and accomplishes world domination. The band plays Don’t Feed the Plants as
          Audrey II begins to move forward and expand to engulf the entire theater.
        </p>
        <p>
          <strong>Problem:</strong> We need a way for Audrey II’s vines to “grow” along the ceiling and floor
          of the theater, seemingly on their own. The lyrics of the song imply that the plants are coming for
          the audience next, so the goal is the create an experience that will bring those words to life for
          the members of the audience.
        </p>
        <p>
          <strong>Limitations:</strong> Because the ECT is a rented space, permanent damage to the building is
          not an option. This means no drilling or screwing into walls or anything else that will irreparably
          damage the space. The contraption also needs to be more or less silent, so as not to interfere with
          the music or what’s happening on stage. Finally, the mechanics, ropes or inner workings or the set
          up need to be as unseen as possible, giving the impression that Audrey II is truly Alive. Simplicity
          is golden!
        </p>
        <p>
          We are giving you until Saturday at midnight to come up with and submit a response. You can submit
          your responses in any form; drawings, diagrams or whatever you think will convey your ideas clearly.
          Please accompany any images you provide with a brief description of your concept. Responses DO NOT
          need to be high fidelity AT ALL. Simple sketches on notebook paper will do just fine, for example.
          Finally, your solution is not expected to be a usable product for the final show and your submission
          does not have to be “final” in any way. It takes far longer than a week to create something like
          this.
        </p>
        <p>
          Thank you again for your interest in the Little Shop of Horrors production team! I look forward to
          seeing your ideas.
        </p>
      </Quote>
      <p>
        My amazon search history was a bit of a mess for the next few days. I sent the following back at about
        6pm on Saturday:
      </p>
      <Quote>
        <span>Hey Guys!</span>
        <p>
          I have a couple of ideas floating around, one of them a lot crazier than the others, but I think it
          could be cool if we can work on it. Less crazy ideas first:
        </p>
        <p>
          I think one of the big things that would help contribute to the audience feeling like they are next
          is promoting the feeling that (to some degree) they are trapped. To this end, I'd have people
          standing behind the curtains at the rear doors of the theater and slowly feeding out ivy/vines and
          stuff (probably reinforced with stiff wire to give it some structure). This makes it feel Audrey II
          has already engulfed the building and there isn't really any escape, it's just a matter of time
          before Audrey II gets hungry and raids her "food stash" that is the audience. Having curtains in the
          back of the ECT instead of doors really helps this, because we can put things (like plant
          appendages) through the curtains and into the house without the people behind the curtains being
          particularly noticeable.
        </p>
        <p>
          Depending on what's happening onstage as well, we could do something similar from the stage and use
          some non-stiff form of structure for the ivy/vines. That way, we could have people behind the rear-
          and side-stage black travelers slide ivy/vines out along the deck, then when it reaches the end of
          the deck it would naturally droop over the side and look like it's starting to crawl off the stage
          and down into the house. We'd just have to mark out exactly how long to push it out because this
          effect would only work until the ivy reaches the floor of the house.
        </p>
        <p>
          Depending on the configuration of the house lights, it could be interesting to only use some of them
          as actual "house lights" for intermission/preshow/etc, and have others gelled a deep green, so those
          could slowly come up during the final scene, like the whole room is becoming organic and taking the
          audience hostage. (The phrase "taking the audience hostage" probably just put me on a government
          list somewhere...)
        </p>
        <p>
          Now for the slightly crazier idea:
        </p>
        <p>
          I was originally thinking about having vines travel across the walls/ceiling of the house, but had
          trouble coming up with a solution where the vines would be under control, could be moved during the
          show, and wouldn't have a very obvious track/cable system guiding them and supporting them along the
          wall. So, I came up with a slightly different solution that might look cool. There's a crystalline
          compound that you can buy pretty cheaply that glows bright green for a long time after being exposed
          to UV light. So, I propose taking a bunch of that compound and suspending it in vegetable oil.
          (Vegetable oil because the compound dissolves and stops working in anything water-based, and because
          of the consistency of oil vs water.) Then, taking that mixture, putting it under a blacklight (also
          pretty cheap on Amazon) to charge up the "glow", and putting it in one of those pressurized "garden
          weed sprayers". Then, it's not particularly hard to hook one of those sprayers up to a clear hose
          instead of a sprayer nozzle, and we could use a valve to slowly open up the container and allow the
          glowing green (vegetable oil) "plant slime" to creep its way through the tubes. I'm thinking we
          could put these tubes up all across the ceiling of the house, maybe originally coming from the top
          corners of the proscenium and crawling/zig-zagging organically across the ceiling. Long distances of
          clear vinyl tubing that would show the green goo encroaching on the audience's territory from above
          are also pretty cheap on amazon, and could be temporarily suspended from the ECT ceiling with
          command hooks, which makes the whole rig completely removable and non-damaging. The nice thing is,
          because before this stuff is put through the tube, the tube is clear, and because all the tubing is
          way above the audience's heads and above the lights against a black background (the ceiling), it'd
          actually be pretty hard to spot during the show until the plant slime is deployed.
        </p>
        <p>
          The idea here is to make it look like plant veins/vines/appendages are growing across the ceiling at
          an alarming rate, and are quickly taking over the very structure of the ECT. I know that the ECT has
          two movers and I know one is not working, but there's one which I've heard conflicting information
          about whether it's working or not. One thing that might supplement this idea would be to have a
          program in the lighting board where that mover thrashes around angrily once the slime has approached
          it along the ceiling, as though it's being attacked by the plant and trying to defend itself. Then,
          maybe having it flash quickly, then hang limply, as though dead. Or, more ominously, have it lit
          white when it's thrashing about originally, then slowly become more controlled with it's movements
          and calmer, then point directly at the rear door/ground and switch to a green gel, as though Audrey
          II has gotten into its inner workings and taken control. This is all dependent on a lot of
          experimentation with what the lights/board can do and how well we can program them, but it could be
          cool to try.
        </p>
        <p>
          The slime/tubes/death-by-plant-goo idea should be fairly do-able, and I priced some supplies out
          tentatively (it's surprising what you can buy on Amazon these days). I think we could do this for
          like $100-$130 maximum, and that's allowing a good amount of leeway for "oh shit, we gotta buy ____"
          moments, as well. Note that this idea is also non-toxic and completely non-carcinogenic. (The first
          iteration of this idea was actually mildly cancer-inducing, but I scrapped that one pretty quickly
          :P )
        </p>
        <p>
          I'd love to hear what you guys think and any problems you might be seeing that I'm not seeing yet.
          I'd especially love to hear if you guys think the plant slime thing is do-able, or if there's an
          even better way to do something similar.
        </p>
        <p>
          Thank you so much!
        </p>
      </Quote>
      <p>
        That Monday, at about 6 pm, I was offered the job of Technical Director. The first thing we did is have
        the current technical staff (which consisted of myself, the assistant technical director, the director
        and assistant director, and the also-newly-appointed set designer) meet to see what we wanted to do
        about these vines. The set designer had also participated in the challenge and we came up with mildly
        similar ideas. Instead of a liquid-based vine, however, he wanted to use LED strips. We all decided to
        go with that idea pretty quickly. I also felt more qualified to be responsible for creating it: I was a
        better amateur electrician than an amateur chemist.
      </p>
      <h2>The Plan</h2>
      <p>
        I set to work researching LED strips, control systems, and their power requirements. I wanted to have at
        least a basic idea of how many vines I could create, control, and power within our budget. In the end, I
        settled on 5-meter strips of WS2812B three-color individually-addressable LED units, at a density of 60
        LEDs per meter. They were also available with a 120 LED/meter density, but that would've doubled my
        power requirements and the control channels required, as well as nearly doubled the price of the strips
        themselves. With that in mind, the 60 LED/meter variety would be fine. WS2812Bs are some of the cheapest
        individually-addressable strips on the market, which allowed me to buy more of them to really maximize
        the vine effect. After some debate and some sneaky measurements of the theater whenever we had a chance
        to get inside, we had a plan: I'd buy one 5-meter test strip to make sure I can get the control and
        power systems working right, then if everything went well I'd buy six more strips for a total of 35
        meters of vines over the ceiling.
      </p>
      <p>
        It was at this point that I began taking as many pictures of the ceiling of the theater as I could. I
        had friends who did shows fairly regularly in the same space, so in the few minutes between the end of
        their show and the end of their reservation for the space, I'd hop on stage and take pictures of the
        ceiling conduits and topology, and measure out approximate distances for the vine runs. Our space, seen
        from above, is shaped like a short, fat, asymmetrical ice cream cone with a single scoop of ice cream.
        The stage occupies the cone portion of the space, and the audience sits in the ice cream, with the
        control booth located along the back wall behind a window, approximately where you'd expected the cherry
        on top to be. The plan was to hide the vines on the ceiling behind ventilation ducts and pieces of
        equipment over the stage, and have them snake out from the stage over the audience's heads. It's a small
        space, so they could actually fill the ceiling area quite well. I'd keep the control and power systems
        with me in the booth, and run a large bundle of cables out the window and along the ceiling from behind
        the audience, to meet the vines at their tips closest to the booth.
      </p>
      <p>
        The rest of my adventures with getting the electrical elements of this system working are described in
        more detail in the "Scaling Up" section below. However, concurrently, I began work on another aspect of
        this project: getting the LED strips to actually look like vines.
      </p>
      <h2>Camouflage, Part 1</h2>
      <p>
        Our primary concern was figuring out how to smooth out the light so it didn't just appear as a sequence
        of individually-discernible pixels that lit in sequence. In my previous work, I spent most of my time
        doing lighting design and electrician work for the clients in our venue. Naturally, then, my first
        thought was to use a frost gel. Gel is a material used mostly to change the color of a light in a
        theater. Until a few years ago, most theater lights were all white, and lighting designers would use gel
        to change the color that showed up on stage. Frost gel has no color (generally), and instead is designed
        to heavily diffuse the light that passes through it - it's essentially a rugged, thin plastic that looks
        somewhat like white parchment paper. I bought a sample sheet (2 feet square) of the toughest frost I
        could find and set to work. My goal was to create a cylinder out of the gel, pass the LED strips through
        it, and have the gel cylinder capture and diffuse the light to look like a vine. Once I had a long
        cylinder with the LED strip in the middle, I would bend that into curves so it would appear as though
        the vines were snaking along the ceiling naturally.
      </p>
      <p>
        Those of you with a background in differential geometry have already figured out that this is a hopeless
        venture. For the rest of us, allow me to introduce you to the wonderful and frustrating concept of
        Gaussian Curvature. Gaussian curvature, in this case, says that if you take a flat, non-stretchy surface
        (like gel), you can only curve it along a single axis. By rolling it into a cylinder, I had already used
        up my one curve, it became mathematically impossible to introduce a snakelike curve into that cylinder
        without creasing, tearing, or deforming the gel in some way. (Interestingly, this is also the reason why
        pinching a slice of pizza in your hand near the crust prevents the tip from drooping while you're trying
        to eat it - you're "using up the one curve" with the fold you made near the crust, which prevents the
        droop from happening on the other axis.)
      </p>
      <p>
        I tried my very best to break the laws of mathematics with this one, but I couldn't figure out how to
        make a nice even curve that looked like a vine. After all, a vine that turned at sharp angles wasn't
        particularly realistic. I even went so far as to create a 3D model of the curve I wanted to create, and
        use the modelling software to UV unwrap the 3D shape into a 2D cutting template.
        That <em>almost</em> worked, and was at least mathematically possible, but required too much precision
        in practice for me to effectively be able to mass produce nice-looking curves. With that, I knew we had
        to ditch the gel idea and go with something else.
      </p>
      <p>
        During all of this camouflage R&D, I was also working on building up a smaller, functioning version of
        the electronics as a proof-of-concept, as well as planning out how I would scale those electronics up to
        handle the entire rig at once.
      </p>
      <h2>A Small-Scale Test</h2>
      <p>
        I already owned the arduino and some breadboarding supplies that I was planning on using to build the
        control circuits. I built a demo of the control system inside a shoebox, and connected it to our one
        test strip of WS2812Bs in the back of a rehearsal room one day. After the rehearsal, we had a meeting of
        the production staff, where everyone was able to see the effect running for the first time. There was no
        camouflage yet, but the LEDs were actually pretty convincing on their own at this point. I now had the
        green light to figure out how to make it bigger. We were planning on having seven strips total, each
        five meters long with 60 LEDs per meter. That amounts to 2100 individually-controlled LEDs, no small
        task.
      </p>
      <h1>Scaling Up</h1>
      <p>
        I had two main jobs in this department: provide power to all the LEDs, and provide control instructions
        to all the LEDs. First, I worked on getting them power.
      </p>
      <h2>Giving the Vines Life</h2>
      <p>
        We planned to have a total of seven 5-meter segments mounted on the ceiling. Each one would be its own
        strip, with a dedicated power line and control line. These LEDs are power hungry: each WS2812B unit
        contains 3 internal LEDs (red, green, and blue), as well as an integrated driver chip to receive the
        control signals and manipulate the three colors accordingly. Each of these 3 internal sub-LEDs draws
        about 20 mA at maximum load, meaning a fully-bright white WS2812B unit is drawing about 60mA at 5V. That
        doesn't seem too bad when you have a few of them, but I had 2100 of them. Those of you playing along at
        home will calculate a total current draw of 126 A to have every LED in the rig at full white all at the
        same time - the absolute maximum-power scenario for these LEDs. At 5 volts, that is 630 watts of power.
        For comparison, the label inside the full-size fridge/freezer in my kitchen claims a maximum power draw
        of 780 W.
      </p>
      <p>
        Now, each individual 5 meter strip would get its own power run, so the power cables only had to handle
        the theoretical maximum of 18A current draw from any one strip. This is still a very sizable amount of
        current. For comparison, every outlet in my bedroom is wired to a single 15A breaker. (That's at a much
        higher voltage, so there's more power, but conductors only care about current capacity, not voltage.) To
        safely handle the current load of this rig, I had to come up with even higher-capacity cables than those
        inside the walls in my apartment. Furthermore, I was running at very low voltages - the LEDs are
        designed to be driven at 5V DC, so I had to worry about voltage drop as well.
      </p>
      <p>
        Every copper cable, despite its classification as a "conductor," still has some amount of internal
        resistance. For your 3-inch breadboard runs, this resistance is more or less inconsequential. However,
        my longest power run was looking to be around 40 feet long, and the rest weren't far behind it. This
        means that by inserting a cable to run power from my power supplies in the booth to the LEDs on the
        house ceiling, I was also accidentally inserting a very <em>not</em> inconsequential resistor into my
        circuit. Ohm's law tells us that voltage drop is directly proportional to current draw, so my beefy 18A
        of current would be working against me here as well.
      </p>
      <p>
        The above issues are exactly why the high voltage power lines used by your local power company are high
        voltage: delivering power at a hundred thousand volts means <em>much</em> less current is required to
        deliver the same amount of power, which means much less power loss over the miles-long runs from
        substations to your house. I considered following suit and using 12 or 24 volt power supplies with buck
        converters to step the power down to 5V right before the run terminated into the LED strips, but there
        were two main reasons I didn't choose to go that route. (1) The circuitry for the voltage step-down
        would be (relatively) big and heavy compared to the things I was already planning on mounting to the
        ceiling, and it would be a serious hit to my budget to purchase all the necessary components (7 times
        over, since each vine would need its own voltage converter). (2) I'm an amateur electrician at best, and
        I wanted to keep the above-the-audience's-heads voltage to a minimum. In the unlikely event that one of
        my power cables came loose and dropped to the floor, 5V wouldn't be particularly dangerous to anyone
        unless they decided to lick the live power leads.
      </p>
      <p>
        To minimize these issues with voltage drop during power transmission, I chose to go with as thick of a
        power cable as I was able to reasonably acquire. Lower-gauge (larger) cable has a smaller inherent
        resistance and thus would act as a smaller "resistor" in my overall circuit. To get more bang for my
        buck, I bought 4-conductor cable instead of 2-conductor cable. This turned out to be cheaper than
        purchasing twice the amount of 2-conductor cable or a significantly thicker variety of 2-conductor cable
        that would have lower resistance built-in. This 4-conductor cable came in handy because I could bond the
        four conductors into two matching pairs at each end. This, electrically, has a similar effect to my
        using a larger cable, and thus I could pretend as though I was using a thicker cable that would suffer
        from less voltage drop.
      </p>
      <p>
        I ultimately chose to buy two 5V 60A (300W) power supplies. This left me about 30W short of my expected
        maximum power draw, but remember that the maximum power draw is only achieved when all three
        sub-elements (red, green, and blue) are at full brightness, making the whole unit appear white. For our
        specific use-case of mostly-green vines, we were never going to have all the LEDs be white (or, really,
        any of them be white). In practical terms, I was only likely to be using about half of my maximum power
        draw, which meant my two power supplies would be plenty.
      </p>
      <p>
        I also chose to be a little more stingy with my wiring practices: the 5 meter strips were only connected
        to power at one end. For strips this long, that meant that the small copper traces within the strip
        itself had to carry power for a full 5 meters for the last LED in the series. (I use that term
        colloquially, the LEDs were actually wired in parallel within the strip, electrically speaking.) It
        would've been better to have another connection to power at the opposite end of the strip (and maybe a
        third in the middle) for a more even distribution of power, but that was prohibitively expensive with
        the cost of the extra cable. Practically, this had the effect of slightly starving the further-down LEDs
        of power when the earlier LEDs were drawing lots of power. When illuminated full-white, the strip would
        fade to a dim red in the last ~20% of LEDs. Red, as a color, requires less power to create than the
        other colors (red photons are less energetic than other visible photons). When power was tight, the
        elements in the WS2182B units responsible for creating red would hold out longer than their
        other-colored siblings, creating a red tinge.
      </p>
      <p>
        With these decisions made, I had a solution for power. Two 300 watt DC power supplies would (mostly)
        share the load of servicing seven independent power lines, one to each of the seven vines. The vines
        would receive power via their own four-conductor 14 AWG cables, bonded in pairs so they'd behave more
        like two-conductor ~11 AWG cables. This reduced the voltage drop enough that the 2812B units would be
        happy with the power that showed up on their end. Some bench testing with a variable power supply and
        the actual LED strips that I'd be using showed that they were actually fairly fine running at voltages
        much lower than specified in the datasheet - sometimes in the 2-3V range. Making use of my power
        supplies' trim potentiometers meant that I'd actually be able to supply closer to 5.5-5.8V at the
        source, so the overall voltage drop over even my 40-foot runs would be well within tolerable levels.
      </p>
      <h2>Controlling Our Creation</h2>
      <p>
        Now - I turned to the issue of control. One of the reasons that the WS2812B units are so cheap is their
        less-than-ideal control interface. Each unit has two leads for control - Data In and Data Out. Along the
        strip, each one has the data out connected to the next one's data in, putting them in series. They
        effectively behave as a giant series of shift registers - each one holding 3 bytes of data representing
        the PWM values for red, green, and blue. If you've ever chained multiple 595-type shift registers in
        series, it's almost the same idea. There's one <em>very</em> important caveat - there's no shift clock.
      </p>
      <p>
        For the non-shift register experts in the room, think of a shift register as an excel spreadsheet with a
        single row and some number of columns. I can only edit the left-most cell in the row, by signalling to
        the data-in pin of the first 2812B unit in the chain. If I send a 5V ("high") control signal to that
        pin, column A of that spreadsheet gets the value 1. A 0V ("low") signal is the value 0. When a new value
        appears, the value that already was in column A is moved into column B, column B's old value is moved to
        column C, and so on. (That's the 'shift' part of 'shift register.') Values at the end of the last column
        are shifted off the end and disappear.
      </p>
      <p>
        If this were an actual spreadsheet, the natural editing workflow of that first cell would be something
        like (a) type a new value in, then (b) press enter to actually "commit" to my change and cause the
        shift. So if I wanted to send two 0s, for example, I could skip step (a) for the second zero and just
        press enter again on the 0 that's already in column A. In 595-type shift registers, there's another
        signal line in addition to the data lines called the "shift clock," which is the equivalent of pressing
        the enter key in the spreadsheet. Taking that signal high means "shift everything over one spot, and
        fill the newly-blank space with whatever data is currently present on the Data In line." That's how the
        register knows when I'm sending it the next piece of data, whenever I send the shift clock signal.
      </p>
      <p>
        Inside each of the WS2812B unit,s there are 24 'columns' worth of data, separated into three groups of 8
        ones or zeros (bits). These three groups represent the amounts of red, green, and blue to mix to create
        whatever color should be displayed by the LED. The Data Out pin of each WS2812B contains whatever value
        was just shifted off the end of the 24th column, so connecting that to the Data In pin of another 2812B
        unit effectively makes a single 48-column shift register. When I shift in 48 bits, the first 24 end up
        in the second LED down the line, and the last 24 end up in the one I'm directly connected to. The
        300-LED strips are nothing more than very long chains like this.
      </p>
      <p>
        Now that we're all on the same page: the problem. The WS2812Bs <em>have no shift clock</em>. This means
        that if I take the data line to 0V and then, sometime later, 5V, how are they supposed to know if the 0V
        portion was one '0,' two, or more? I have no enter button to press, so they don't know how many times to
        shift! Unlike registers with shift clocks, the 2812Bs determine how much to shift by having <em>very
        strict</em> timing requirements. I control how many ones and zeros I'm sending by controlling the exact
        timing of when I change from 0V to 5V and back again. The data rate of these units, however, is 800KHz,
        meaning that 800,000 bits are sent every second. This means that the durations of any one bit of data
        are incredibly small. The difference between sending a one and sending a zero is four tenths of a single
        microsecond, or 400 nanoseconds. The upside? These LEDs are significantly cheaper than their
        counterparts that have clock lines.
      </p>
      <p>
        I previously mentioned that I'm using an Arduino as the controller for these LEDs. Specifically, I'm
        using an Arduino Mega 2560. The clock speed of the ATMega microprocessor on that board is 16MHz, so the
        absolute fastest that the processor on my controller can do a single operation is 62.5 nanoseconds. This
        leaves very little room for extraneous operations, or I could get my timing off and start sending
        garbage data to the LEDs. This means that any high-level compiled language running on that chip wouldn't
        have the precision necessary to get the timing right. Fortunately, the good people at Adafruit wrote <a
        href="https://github.com/adafruit/Adafruit_NeoPixel" target="_blank" rel="noopener noreferrer">a library
        to fix that issue</a>. They did some crazy things with <a
        href="https://github.com/adafruit/Adafruit_NeoPixel/blob/48da9b34a7e2365fb9984b1a7462a8d9d0798a6f/Adafruit_NeoPixel.cpp#L236-L366"
        rel="noopener noreferrer" target="_blank">assembly instructions embedded directly within their library
        code</a> that allows them to control every individual cycle of the processor, ensuring that they get the
        timing right.
      </p>
      <p>
        In addition to difficulties with CPU timing, I also was working in a very memory-strict environment. The
        ATMega processor only has 8 KB of RAM - which is where all the active data being used by a program is
        kept. 2100 LEDs with 3 bytes each of color data is a full 6.3 KB of RAM. This left me less than 2 KB of
        space to keep all the other bookkeeping and state data for the program, including any space needed by
        library code that I might be using as well as the active call stack.
      </p>
      <p>
        Given all the above, it was a tight squeeze to get the control software working. After many nights
        pulling my hair out and digging around in esoteric documentation, I had written a functioning control
        system. It was, without a double, the worst 200-ish lines of C++ code I have written in my life, and
        hopefully ever will write. I do have a mild excuse for that, though: I didn't actually know C++ at the
        time.&nbsp;&nbsp; :)
      </p>
      <p>
        Once I had come up with a reasonable enough control system for the vines, I needed a way to get my
        control signals from the booth to the vines up on the ceiling. The control cable runs would be nearly
        identical to my power line runs, so I needed to get a 5V control signal nearly 40 feet. Given the
        complexity of the signal being sent, I also needed to come up with a way to do so that minimized
        possible interference and voltage drop that might cause a "high" signal to not be high enough voltage to
        register as a 1 on the other side. Finally, I needed to do so without breaking the budget. The solution
        to all of these problems was fairly similar to my solution to my power problems. This time, instead of
        speaker wire, I used ethernet (Cat5e) cable.
      </p>
      <p>
        Cat5e cable is has a single outer sheath that contains 4 pairs of 24 AWG (very small)
        individually-insulated wires. In particular, I bought 250 feet of UTP cable - Unshielded Twisted Pair.
        The unshielded part is pretty straightforward, there's no additional foil lining inside the cable to
        protect it from interference. While I would've preferred shielded cable, I couldn't find any that met my
        requirements for a price that we could afford. Fortunately, I had twisted pair cable. This means that
        each of the pairs were twisted around each other, like DNA, along the length of the cable. While I don't
        fully understand the physics behind it, from what I do understand: this creates an electromagnetic
        coupling between the two conductors, which can make them more resistant to interference.
      </p>
      <p>
        I was fairly worried about interference due to the environment I'd be working in. I had large AC power
        circuits running all over the ceiling that I'd be running these data lines along. After all, I was
        working in a theater, and many of the high-powered lighting circuits used for stage lighting also call
        that ceiling home. Changes in the electricity in those circuits can create large magnetic fields that
        could induce current in my small data lines, potentially throwing off my data and causing the vines to
        go haywire. I had hoped that some clever routing to keep them as far as possible from the main lighting
        conduits, combined with my use of the twisted-pair conductors, could help me combat that interference.
      </p>
      <p>
        With my interference problem (hopefully) solved, I pulled a trick with my Cat5e cables similar to what I
        did with the power cables: each of my data lines only needed two conductors - data and ground. Since I
        had 4 total pairs, I could combine 4 conductors within the cable for each of those and create two
        "larger-gauge" conductors within a single Cat5e. I chose one conductor from each of the 4 pairs to
        become ground, and the other became the data line. This extra beefiness from my data cables, combined
        with the <em>very</em> low currents needed to send data, made me confident that my voltages would
        survive to the other end of the cables.
      </p>
      <h2>Turning Them into Vines</h2>
      <p>
        I now more-or-less had the following:
      </p>
      <ul>
        <li>
          A control system and accompanying software on an Arduino Mega
        </li>
        <li>
          A test LED strip that was wired up to the control system and working fine.
        </li>
        <li>
          600 watts worth of 5V DC power supplies.
        </li>
        <li>
          A 250-foot contractor's box of UTP Cat5e
        </li>
        <li>
          250 feet of 14/4 speaker cable for power.
        </li>
        <li>
          6 additional 5-meter LED strips, each with 300 LEDs.
        </li>
        <li>
          Miscellaneous additional components for power conditioning and building the final control circuit
          for use during shows.
        </li>
      </ul>
      <h2>Camouflage: Part 2</h2>
      <p>
        Our next step was getting the vines to actually look like vines. A number of us, primarily the director,
        set designer, and myself, decided to get together and see what we could come up with. The set designer,
        as part of his original pitch, had also suggested we use a sheer black fabric to disguise the vines
        while they were on the ceiling (since the ceiling was painted black). However, we still needed a way to
        spread out the light that was flexible and would make the LED strips appear as one continuous strand.
      </p>
      <p>
        A few days worth of meetings and brainstorming later, plus a very productive trip to Home Depot, we had
        our solution: tubing. We bought a small section of clear vinyl tubing that had an interior diameter just
        slightly larger than the width of the LED strips. The clear tubing would help conduct the light into the
        snakelike cylinder shape that we were looking for, but the individual LEDs were too visible. To solve
        that problem, we bought a few cans of "glass frost" spray paint. This is the kind of paint used to make
        glass frosty (but still translucent) for use in bathroom windows or private conference rooms.
      </p>
      <p>
        The plan was to spray-paint the tubing so it'd be a frosted white instead of totally transparent, then
        thread the LED strips through the tubing. Once we had our vines, we could sandwich them between the
        ceiling and the black fabric and staple the black fabric to the ceiling on either side. That way, all
        the weight of the tubing and LED strips would be held by the fabric, and I wouldn't need any sort of
        supports crossing the body of the vines and creating a break in the continuous line of light. As an
        added bonus, the tubing was very flexible and happy to snake around in loops and curves to my heart's
        content.
      </p>
      <p>
        Once we had our short section of tubing painted, the three of us met in the floor lounge of our dorm
        building. The director had some white fabric that we'd use as a stand-in for the black fabric that we
        had yet to purchase - it was more opaque, so if the white fabric worked, the black fabric definitely
        would. We laid it out on the floor, and this is what we saw:
      </p>
      <Video width="1280" controls>
        <source src={"/img/vines/floor-test.mp4"} type="video/mp4" />
      </Video>
      <p>
        You can see, on the right edge of the video, a small portion of the tube that isn't painted. Then,
        there's about an inch of the frost-painted tube before it disappears under the fabric. The tube does a
        great job of uniformly distributing the light, so even if the LED strip inside isn't quite rotated
        correctly, it doesn't matter for the final effect. The light also shines well through the fabric, so it
        should be fairly easy to disguise with the black fabric overhead.
      </p>
      <p>
        We officially had a vine, now I just needed to make more of them!
      </p>
      <h1>Testing and Installing</h1>
      <p>
        Unfortunately, the bulk of the work - attaching the vines to the ceiling and running power and data
        cabling to them - couldn't be completed until we were in the space. I couldn't even cut and prepare the
        data or power cables until after the vines were in place - I didn't know how long to make them. Our
        production schedule has us moving into the theatre on Sunday, and opening night was on Thursday. That
        gave me about 4.5 days to build the entire rig.
      </p>
      <p>
        On Saturday, outside the space, we spray painted all the tubes and pre-cut them, since I knew how long
        each vine would be. Sunday was mostly getting the LED strips all in the tubes, which is a fairly tricky
        process with how tight the tubing is relative to the size of the LED strips. I teamed up with the Stage
        Manager for a few hours and, after some trial-and-error, we had a system down of using some 3D printer
        filament that I had lying around as a pull string. It was just rigid enough that, if we went slow and
        jiggled the tubes a lot, we could push a 5-meter segment of filament through the tube and have it come
        out the other end. At that end, we attached the filament to the LED strips with electrical tape, then
        pulled the entire assembly back through the tube, leaving the LED strips inside. It took a while, but we
        had it ready. The Stage Manager and some crew people were also kind enough to cut the strips of our
        newly-acquired black fabric so it was ready for me to hang.
      </p>
      <p>
        I spent the next three-or-so days at the top of a ladder for nearly the entire period of 10am to 11pm -
        our reservation for the space. My basic process was to eyeball how I wanted the vines to route and
        connect to each other from the ground, then work my way from the onstage end out into the house. Getting
        it started was the hardest, because of the amount of weight dangling since the majority of the strip
        wasn't yet hung. There was a small army of people helping me at this stage. I was the only one ever at
        the top of the ladder due to my familiarity with the system, but there was always someone helping my
        shuffle the ladder between rows of seats and keeping weight on the base so I didn't go flying.
      </p>
      <p>
        Monday morning, I fell out of my lofted bed getting up for the day. I landed on my right arm in such a
        way that I was worried that I had broken it, and I was more or less one-armed for that entire morning.
        After some checks to make sure I hadn't hurt anything seriously, I shifted my priorities a bit for that
        morning and did my best to help out where I could. Once I could manage the pain by Monday afternoon we
        went back to hanging the vines. The injury definitely slowed our progress, though. At this point, the
        theater looked like this:
      </p>
      <Image src={"/img/vines/build.jpg"} alt="A scattering of building supplies throughout the space." />
      <p>
        Once each vine was fully hung, I needed to run data and power to it from the booth. In the weeks leading
        up to our load-in, I had prepped the business end of the LED strips by soldering on power and data leads
        with quick-release connectors on one end. It wasn't that easy for the cables, however. I couldn't cut
        any of my cables until after I had measured them, which means I needed to do each run twice. Once the
        vine was up, I'd put my power and data cable reels in the booth and slowly fish them between pipes and
        along the ceiling until they reached the vine. Then, I could mark the length needed, cut them with some
        spare length for service loops, pull them down to have connectors soldered, and move to the next vine. I
        wasn't able to do any of my multi-conductor-to-one-connector solder jobs on the cables until after they
        were cut - I had to leave the cables on the reel since we didn't have enough for me to be able to
        over-cut and then trim them on one side later.
      </p>
      <p>
        I spent my time in the theater measuring and cutting cables, and once the theater closed at 11pm I sat
        down at a soldering station and got to work getting connectors attached to all the cables. Eventually, I
        had all the cables prepared and began the process of re-running them from the booth to their
        terminations at the ends of the vines, this time to actually be connected. We were ready for testing.
      </p>
      <p>
        Surprisingly, there weren't any bugs serious enough in the code that I couldn't get the whole system
        running reliably within half a day. All of my power lines worked on the first try, as well as the data
        lines. This was no small miracle - I hadn't actually had a chance to test the data lines until this
        point, since they weren't even soldered until right before they went up onto the ceiling. Here's what
        one of our first test runs looked like, from the perspective of an audience member sitting in the center
        of the house.
      </p>
      <Video width="1920" controls>
        <source src={"/img/vines/installed.mp4"} type="video/mp4" />
      </Video>
      <p>
        That's actually only three of them. I didn't have a wide-enough camera to take a video of the entire
        ceiling running at once. Now, it just came to fine tuning: modifications to the timing, tweaks to the
        color, and practice with the controls. User interface was definitely at the bottom of the list for this
        one, so the control surface looked like this:
      </p>
      <Image src={"/img/vines/controller.jpg"} alt="A mess of wiring resembling a controller." />
      <p>
        You can see the arduino mega on the right, which was the brains of the operation. Seven individual data
        lines were broken out from the arduino to the breadboard, where they were attached to the Cat5e data
        lines (seen labelled with red spike tape) alongside pull-down resistors. There were also buttons and a
        few status LEDs to allow the operator to control which effect was playing and see what was going on with
        the internal state. It's a very rough system, but it worked.
      </p>
      <p>
        The vines actually blended in fairly well. They also were much harder to see in the dark (until they
        were lit), which made them especially surprising during the actual effect:
      </p>
      <Image src={"/img/vines/blackout.jpg"} alt="The vines turned on during a complete blackout." />
      <p>
        We used the vines only at the very end of the actual show: when the four narrators of the show were
        describing the plant's takeover of the world in the final song. Here's what it looked like:
      </p>
      <Quote>
        <p>Our four narrators are standing center-stage, describing the near future in what's effectively an
          epilogue to the show. They sing:
        </p>
        <span>
                    <em>And the plants proceeded to grow and grow- and grow<br /> And begin what they came here to
                        do<br /> Which was essentially to<br /> Eat Cleveland and Des Moines<br /> And Peoria and New
                        York<br />And this theater!</em>
                </span>
        <p>
          The four point to the ceiling on that last line, and every light in the house and onstage goes
          completely black. The vines begin their crawl across the ceiling, taking about 10-15 seconds to
          complete the journey, while the pit underscores it with dramatic music. A few measures later, the
          lights on the stage return to normal and the area is transformed: (non-electronic) vines cover the
          stage and the performers. People who were previously eaten by the plant appear in ghostly form, also
          covered in vines, in the aisles in the house, and the vines overhead have completed their animation
          and are now lingering above the audience ominously. The cast sings the last portion of the song and
          the show ends.
        </p>
      </Quote>
      <h1>Debrief</h1>
      <p>
        This was a crazy project, but I'm very glad I got to be a part of it. I learned a lot along the way:
        both intellectually and personally. This project helped me be more comfortable as a leader without
        feeling the need to micromanage - I could put people in charge of tasks and feel comfortable relying on
        their abilities, because I knew and trusted them. Being in a situation where I wasn't physically capable
        of doing everything myself forced me into the then-uncomfortable position of having to rely on other
        people, and I grew because of it. I think, after this project, I'm a much more effective leader in being
        able to delegate work where needed and keep my eye on a bigger picture.
      </p>
      <p>
        There's so much more to tell about this project than even what I was able to put here. From the
        perspective of the audience, I think this was a success. The vines worked, and people were amazed at
        what this small student group could pull off in a relatively short amount of time. From my perspective,
        this was a beta project. It was pretty rough around the edges, and I think I could do a lot more with
        the code now that I actually have learned a bit of C++ in class. I'm excited to come up with some new
        projects to do with all this equipment, and see what other kinds of art I can make from this.
      </p>
      <p>
        This project really does lie at the cross between my love of technology and innovation and my love of
        performance art, and I hope to do more projects like this in the future.
      </p>
    </>
  );
}
