import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { ContentContainer } from "../components/ContentContainer";
import { ProjectCourseware } from "./projects/ProjectCourseware";
import { ProjectDetail } from "./projects/ProjectDetail";
import { ProjectReactTeaching } from "./projects/ProjectReactTeaching";
import { ProjectReality } from "./projects/ProjectReality";
import { ProjectVines } from "./projects/ProjectVines";

const ProjectEntry = styled(Link)`
  display: flex;
  width: 100%;
  padding: 20px 0;
  flex-flow: row nowrap;
  color: inherit;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  transition: transform .15s ease-in-out;
  cursor: pointer;

  :hover {
    transform: translateX(8px);
  }
`;

const ProjectTitle = styled.div`
  font-family: Lato, sans-serif;
  font-size: 2rem;
`;

const ProjectDescription = styled.div`
  margin-top: 5px;
  margin-left: 10px;
  color: #222;
`;

interface Project {
  link: string;
  name: string;
  description: string;
  component: React.FC;
}

const projects: Project[] = [
  {
    link: "reality",
    name: "Reality",
    description: "Distributed multiplayer game engine for building in-browser experiences easily",
    component: ProjectReality,
  },
  {
    link: "vines",
    name: "Vines",
    description: "Hardware and software implementation of a custom special effects system controlling over 2000 three-color LEDs",
    component: ProjectVines,
  },
  {
    link: "react-teaching",
    name: "React Course Development",
    description: "Development of a series of lectures currently used to teach React.js to CSE 331 students at UW",
    component: ProjectReactTeaching,
  },
  {
    link: "courseware",
    name: "UW CSE Courseware Development",
    description: "Design and development of a suite of internal tools for managing high-enrollment courses in the Computer Science department",
    component: ProjectCourseware,
  },
]

const ProjectDirectory = () => {
  return (
    <ContentContainer>
      {projects.map(project => (
        <ProjectEntry to={`/projects/${project.link}`} key={project.link}>
          <div>
            <ProjectTitle>{project.name}</ProjectTitle>
            <ProjectDescription>{project.description}</ProjectDescription>
          </div>
          <div><img src={"/chevron-right.svg"} alt={"Continue"} width={24} height={24} /></div>
        </ProjectEntry>
      ))}
    </ContentContainer>
  );
};

export const Projects = () => {
  return (
    <Switch>
      {projects.map(project => {
        const DetailComponent = project.component;
        return (
          <Route path={`/projects/${project.link}`} key={project.link}>
            <ProjectDetail name={project.name}><DetailComponent /></ProjectDetail>
          </Route>
        );
      })}
      <Route><ProjectDirectory /></Route>
    </Switch>
  );
};
