import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AnimatedPath, Side } from "../curveGenerator";
import { SlideInFromLeft, TextFallIn } from "./animations";

const WIDTH: number = 350;
const NOISE_SCALE: number = 12;
const TIME_SCALE: number = 0.00015;

const SidebarContainer = styled.div`
  height: 100vh;
  width: ${WIDTH}px;
  //
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const PathContainer = styled.svg`
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: ${WIDTH + NOISE_SCALE}px;
  animation: ${SlideInFromLeft(WIDTH)} 1.75s cubic-bezier(.18, .91, .25, .96);
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, .6));

  & > path {
    fill: ${props => props.theme.accent};
    stroke: none;
  }
`;

const Header = styled.div`
  font-family: Ubuntu, sans-serif;
  font-size: 40px;
  color: ${props => props.theme.text.light};
  text-transform: uppercase;
  margin-top: 10px;
  //
  opacity: 0;
  animation: ${TextFallIn} .8s ease-out forwards;
  animation-delay: 0.85s;
`;

const Nav = styled.div`
  margin: auto 30px;
  align-self: flex-start;
  font-family: Lato, sans-serif;
  font-size: 20px;
  color: ${props => props.theme.text.light};
  line-height: 2;
  //
  opacity: 0;
  animation: ${TextFallIn} .8s ease-out forwards;
  animation-delay: 1.05s;
`

const NavLink = styled(Link)<{selected: boolean}>`
  text-decoration: none;
  color: inherit;
  font-family: inherit;
  display: block;
  transition: transform .1s ease-in-out;
  
  :hover {
    transform: ${props => props.selected ? "none" : "translateX(5px)"};
  }
`

export const Sidebar: FC = (props) => {
  const animationFrame = React.useRef<number>();
  const [path, setPath] = useState("");
  const [pathTracker] = useState(new AnimatedPath(Side.RIGHT, TIME_SCALE, NOISE_SCALE, "andrew"));

  const animate = (time: number) => {
    setPath(pathTracker.renderToPathString(time, WIDTH, window.innerHeight));
    animationFrame.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    animationFrame.current = requestAnimationFrame(animate);
    return () => {
      if (animationFrame.current) cancelAnimationFrame(animationFrame.current);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const location = useLocation();
  const links = {
    "/about": "About Me",
    "/resume": "Resume",
    "/projects": "Projects",
  };

  return (
    <SidebarContainer {...props} >
      <Header>Andrew Gies</Header>
      <Nav>
        {Object.entries(links).map(([path, text]) => {
          let selected = location.pathname.startsWith(path);
          let allowAnimate = location.pathname === path;
          return <NavLink selected={allowAnimate} to={path} key={path}>{text}{selected ? " ·" : ""}</NavLink>;
        })}
      </Nav>
      <PathContainer {...props}>
        <path d={path} />
      </PathContainer>
    </SidebarContainer>
  );
};
