import React from "react";
import styled from "styled-components";
import { ContentContainer } from "../components/ContentContainer";

const Portrait = styled.img`
  width: 150px;
  float: left;
  margin-right: 20px;
  border-radius: 5px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
`;

const AboutContainer = styled(ContentContainer)`
  font-family: Average Sans, sans-serif;
  font-size: 1.1rem;
  line-height: 1.4;
  text-align: justify;
  text-indent: 20px;
`;

const Intro = styled.div`
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-indent: 0;
`;

export const About = () => {
  return (
    <AboutContainer>
      <Portrait src={"/portrait.jpg"} alt={"Portrait of Andrew"} />
      <Intro>Hi, I'm Andrew.</Intro>
      <p>
        I'm currently a student at the University of Washington, in my fourth undergraduate year. I'm working on a BS
        in Computer Science, as well as a concurrent BA in Drama: Design. After my BS, I'll be completing an MS in Computer
        Science, also at UW.
      </p>
      <p>
        I'm deeply interested in creating tools to enable people to create spectacular new things, whether they are new
        pieces of software for accelerating web development, or new hardware for creating the next generation of art and
        theatre. I'm also passionate about opening the world of technology to everybody who wants to learn, and I've been
        a TA for the Allen School of Computer Science for nearly 2 years.
      </p>
      <p>
        Learn more about what I've been up to by exploring this site, or drop me a line at andrew AT andrewgies.com. I'm
        always looking for opportunities to learn new things and get involved in solving interesting problems.
      </p>
    </AboutContainer>
  );
};
