import { keyframes } from "styled-components";

export const SlideInFromLeft = (width: number) => keyframes`
  0% {
    transform: translateX(-${width}px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const TextFallIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
