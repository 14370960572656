import React from 'react';
import { useMediaPredicate } from "react-media-hook";
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import styled, { ThemeProvider } from "styled-components";
import { Sidebar } from "./components/Sidebar";
import { TopBar } from "./components/TopBar";
import { About } from './pages/About';
import { Projects } from './pages/Projects';
import { Resume } from './pages/Resume';

const PageContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-image: url("/crossword.png");
`;

const PageContent = styled.div<{ wide: boolean }>`
  position: absolute;
  top: ${props => props.wide ? 0 : "100px"};
  left: ${props => props.wide ? "350px" : 0};
  right: 0;
  bottom: 0;
`

const theme = {
  bg: "#e9e9e9",
  accent: "teal",
  text: {
    light: "#EFEFEF",
    dark: "black",
  },
}

const App = () => {
  const wideLayout: boolean = useMediaPredicate("(min-width: 1000px)");

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <PageContainer>
          {wideLayout ? <Sidebar /> : <TopBar />}
          <PageContent wide={wideLayout}>
            <Switch>
              <Route path={"/about"}><About /></Route>
              <Route path={"/resume"}><Resume /></Route>
              <Route path={"/projects"}><Projects /></Route>
              <Redirect to={"/about"} />
            </Switch>
          </PageContent>
        </PageContainer>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
