import React from "react";
import { Link } from "react-router-dom";

export const ProjectCourseware = () => {
  return (
    <>
      <h2>Introduction</h2>
      <p>
        In Spring Quarter 2019, I became a TA for CSE 331, Software Design and Implementation. This is one of
        the core courses for the BS track, and is one of the largest courses in the department outside of the
        non-major introductory courses. It has no prerequisites beyond those intro courses, and we expect most
        students to take it within their first few quarters being in the major. The stated purpose of the course
        is to take students who have exactly 20 weeks worth of Java programming experience in their whole lives,
        and teach them the basis of high-level software design and good practices. At the end of the course,
        students (a) understand how to break down large projects into smaller, maintainable portions, (b) are
        familiar with high-level design patterns and good object-oriented design concepts, and (c) are more
        confident writing code that's designed from the start to work correctly and reliably, instead of coding
        by accident or relying on an oracle to ensure correctness.
      </p>
      <p>
        During that quarter, I taught a section of about 30 students and worked closely with the infrastructure
        lead: an experienced TA who had been working on 331 for many quarters and managed much of the backend
        code that made the course function. As a large course (in Spring 2019, ~230 students), the work of
        assigning and grading projects is not possible to complete manually. Thus, a suite of software was
        created long ago to help manage the course. That software is responsible for tasks like:
      </p>
      <ul>
        <li>
          Pushing assignment starter code to students' git repositories.
        </li>
        <li>
          Querying the status of student repositories.
        </li>
        <li>
          Collecting student submissions by cloning their repositories to a grading environment, automatically
          calculating any relevant lateness penalties, if applicable.
        </li>
        <li>
          Sending automated status emails to students about their submissions.
        </li>
        <li>
          Collecting TA feedback from the grading environment and releasing it to students through pushes to
          their central repositories and transfers of data to the gradebook.
        </li>
        <li>
          General bulk operations on student repositories when necessary.
        </li>
      </ul>
      <p>
        In the past few years, there were <em>many</em> changes to the primary software stack of the course that
        necessitated changes to the way this course software worked. For example, we switched from Subversion to
        Git for version control, from Ant to Gradle for building and grading student code, from Java 8 to Java
        11, and from Eclipse to IntelliJ as the officially supported IDE. Much of the old software was hastily
        patched to support the changes, and over time became quite a mess. There simply wasn't time during the
        school year to maintain the software properly. The software became the stuff of legend, that only select
        TA wizards could even hope to understand or use effectively.
      </p>
      <p>
        As the summer of 2019 was approaching, and the previous infrastructure wizard was graduating, it fell to
        me to pick up where he left off. Fortunately, I wasn't scheduled to take classes during summer quarter.
        Working on the course became my full-time job during the quarter - I didn't have normal TA
        responsibilities like holding regular office hours or grading student assignments, and instead was
        primarily focused on modernizing the course and rewriting this infrastructure. (I also was in charge of
        updating the final two-or-so weeks of course content to more effectively teach the new material we were
        introducing. Read about that story in <Link to="/projects/react-teaching">its project page</Link>.)
      </p>
      <h1>The Requirements</h1>
      <p>
        We had some requirements for the new software, based on issues we had encountered in previous quarters
        with using and scaling the software. These primary requirements are discussed below, alongside the
        paradigms I adopted during development to fulfill them.
      </p>
      <h2>Be Reusable</h2>
      <p>
        This project was an exercise in modularization and standardization. The previous software was very
        opinionated when it came to usage workflows, course organization, and course policies. Over time, a
        course naturally changes to reflect new technologies or improvements to the curriculum, as well as the
        personal opinions and preferences of the professor currently teaching. Since the professor in charge
        often changes on a quarterly basis, the course software needs to change to reflect their new policies.
        It was a principal goal of this project to reduce the overhead involved in making these changes.
      </p>
      <p>
        There's a lot of data associated with an active course: student and staff rosters, grading groupings,
        feedback from TAs, and student lateness tracking to name a few. Some of this data is stored in a
        centralized staff repository, while other data is stored in a grading environment in one of the
        department's instructional servers. The location of any particular piece of data was largely determined
        organically as different components of the course evolved over time, so I set out to standardize these
        locations and filenames. I also centralized the hardcoded locations of these files to a single
        configuration script, which allows it to be changed easily in the future if some professors prefer a
        different organization to the staff repo, for example.
      </p>
      <p>
        This centralization of configuration data was fundamental to the rewrite. In addition to basic file
        paths, decision-making code was also centralized. This means that scripts that assigned grades based on
        unit testing results, for example, didn't assume any particular naming convention for test packages, or
        how to interpret test results. In Summer 2019, we had an assignment with nearly an order of magnitude
        more unit tests than any other assignment. Instead of just creating a 1:1 ratio between tests passed and
        points earned, we decided that this particular assignment would be graded using percentages. Making that
        change required only a single if statement to be added to a configuration file, without my needing to
        touch the actual code that assigned student grades. Because the code that assigns grades didn't make any
        assumptions about how it should interpret test results, it was easy to make the change that was: (a) in
        context with other grading configurations, (b) easy to understand, (c) hard to break, as the actual
        logic was isolated from any unintentional changes.
      </p>
      <p>
        I also chose to define configurations as standalone functions instead of simple constants in the code.
        These functions had a mostly-standard set of parameters that included contextual data like the ID of the
        current student under consideration and the current homework assignment being operated on. Many of the
        configurations we used in Summer 2019 simply ignored these parameters, but including them early in
        development means that it's easy to later write configuration that special cases particular students or
        assignments to, say, offer extra late days to a student who got the flu and had a doctor's note. By
        choosing to use dynamic code that received information about the operational context of that
        configuration value, I made the configuration files much more flexible. This reduces the amount of "nuts
        and bolts" modifications that will be necessary to the core code in the future.
      </p>
      <p>
        Much of the previous course software was also extremely opinionated about the expected order of
        operations during the lifecycle of the course. For example, the assignment collection code assumed that
        it was being run at the exact moment of the due date, and simply determined lateness based on the
        existence of a submission at the time of running. The assignment release code, that pushes starter code
        to student repositories, was also inextricably linked with the code that releases assignment
        specifications to the course website. In my rewrite, operations like the assignment collection code no
        longer make assumptions about the conditions under which they're running, and separate operations are
        completely independent of each other. While the other code may have been slightly more "user friendly,"
        in that it can do more with less interaction, I opted to require the user to maybe run two scripts
        instead of a single script, in favor of the increased flexibility that offers. In Summer 2019, we took
        advantage of this by releasing assigment specifications to the course website early, to allow students
        to read ahead and be more prepared, even before we had finalized the starter code.
      </p>
      <p>
        Finally, I wrote the software with an understanding of the practical messiness of responding to the
        activities of several hundred humans. For example, if a student got sick and we decided to give her an
        extra week to finish an assignment, we could simply run the original assignment collection script with a
        flag like "--exclude sally" and then, a week later, run it again with "--only sally". Since, as
        described above, the code makes no assumptions about which students should be involved, when things are
        happening, or what the "correct" way assignments should be submitted are, it's easy for the
        infrastructure TA to respond to these situations in stride.
      </p>
      <h2>Be Maintainable</h2>
      <p>
        The previous course software was an odd mixture of bash scripts, makefiles, python 2 scripts, and java.
        I chose to move completely to python 3 for this project for a few reasons:
      </p>
      <ul>
        <li>
          It's readable, even if you don't know python. Python has been described to me as "executable
          pseudocode," and I agree with that evaluation. Given the python community's core commitment to
          readability over brevity, new TAs who aren't familiar with python can learn quickly and understand
          how to modify configurations with little overhead.
        </li>
        <li>
          It's modern. Python 2 is reaching its EOL, and modernizing will ensure that we'll be able to use our
          scripts when the department eventually removes python 2 from the instructional computing cluster.
        </li>
        <li>
          It's high-level. Complex operations like copying entire file trees or making HTTP requests to a
          remote server can be expressed in one line, without my needing to worry about OS-specific details.
        </li>
        <li>
          It has a strong support for sub-processes. Many of the course operations involve manipulating git
          repositories or invoking gradle tasks. Python 3's subprocess module makes it easy to write code that
          runs arbitrary shell commands while capturing the standard output streams and return codes for easy
          access programmatically.
        </li>
      </ul>
      <p>
        In June, I didn't actually know python. I started the quarter by putting myself through a focused dive
        into python for about a week, after which I was competent enough with the language that I could start
        writing some of the simpler scripts. After some slightly slower going for the first few scripts, I
        quickly became comfortable and was able to develop new scripts very quickly.
      </p>
      <p>
        I think choosing to unify the software to a single, high-level scripting language makes the code much
        more maintainable in the long term. TAs attempting to understand how the code works no longer need to
        understand the features of Unix make, python, java, and bash (as well as the esoteric interactions
        between those different technologies.) Instead, they can learn the basics of python fairly easily and
        start reading and modifying code quickly.
      </p>
      <h2>Be Bulletproof</h2>
      <p>
        Likely more than 50% of the lines of code in the new software suite are error checking. Many times, the
        operations I'm performing are only a few lines of code long at a time, followed by 10-or-so lines of
        checks for different error conditions to allow it to respond properly to different errors and provide
        helpful messages whenever necessary. The software was designed from the ground up to make very few
        assumptions about the state of its environment or the competence of its user. For example, configuration
        files aren't assumed to actually exist, and are double-checked before being accessed. If the user forgot
        to create one, they're presented with a friendly message before the script exits.
      </p>
      <p>
        Since student code is central to many of the operations of the course software, it also has to be
        prepared to encounter situations that it isn't expecting. It isn't unheard of for one or two students a
        quarter to accidentally nuke their entire repository by running commands they don't understand, or
        commit files that are specific to their machines and break their code in the grading environment. When
        operating on student repositories, things like build files and repository structure are double-checked
        (or replaced with known-good copies, when possible) before student code is run. Grading and
        repository-management code, especially, were developed with the assumption that there may be malicious
        students attempting to abuse or affect the system in some way.
      </p>
      <p>
        Many of the operations performed by this software are also bulk operations: push these files to X
        student repositories, assign grades to Y students for these assignments, etc. Due to the large amount of
        error checking at every step of that process, many bulk operations are able to proceed even in the case
        of failures. Students whose code crashed the grading system are simply added to a list and reported at
        the end, while the software is able to continue operating on all the other students. This reduces the
        amount of manual intervention required on the part of the user, which can reduce the potential for
        errors like forgotten students.
      </p>
      <h2>Be User Friendly</h2>
      <p>
        I chose to assume as little as possible about the intentions of the user. While being careful about
        avoiding feature bloat, I did include a number of helpful command-line options that would be useful. I
        defined "useful" as any option that I would use at least once throughout the lifecycle of a quarter, or
        in response to common exception conditions like a sick student or a school holiday.
      </p>
      <p>
        I also chose to make the scripts fairly verbose both with usage and during operation. Every executable
        script uses python's argparse library to automatically generate appropriate responses to the -h/--help
        flag. In addition, most arguments use explicit --full-length-names to improve readability of commands
        when being used, and avoid accidental mixup of argument orders. Finally, I implemented a complete
        logging utility that is used liberally throughout all scripts, and has many of the standard
        info/debug/warn/error logging channels.
      </p>
      <h2>Be Accountable</h2>
      <p>
        As mentioned above, the logging utilities collect a large amount of data about the progress of any given
        operation. Logging data is also echoed, with additional metrics, to a log file stored on the
        instructional servers. There are also multiple fallbacks to ensure that logging can persist even if user
        permissions are insufficient to generate log files in the usual location.
      </p>
      <p>
        The course software also avoids deleting things whenever possible. For example, the script that
        generates TA feedback templates check for the existence of a file with the same name before modifying
        anything, and will back up the contents of that file before changing anything to ensure that any
        relevant data in that file can be recovered if necessary.
      </p>
      <h1>Moving Forward</h1>
      <p>
        I completed most of the development during summer quarter, and switched the course fully over to the new
        system. As I'll be staying on the course for at least a few more quarters, I plan to round out the
        system with some miscellaneous tools, as well as develop a more complete set of user manuals.
      </p>
    </>
  );
};
