import SimplexNoise from "simplex-noise";

interface AxialPoint {
  onAxis: number;
  offAxis: number;
}

interface Curve {
  anchorStart: AxialPoint;
  controlPoint: AxialPoint;
  anchorEnd: AxialPoint;
}

const avg = (a: AxialPoint, b: AxialPoint): AxialPoint => {
  return {onAxis: (a.onAxis + b.onAxis) / 2, offAxis: (a.offAxis + b.offAxis) / 2};
};

export enum Side {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT
}

export class AnimatedPath {

  private readonly noiseGen: SimplexNoise;
  private readonly flowSide: Side;
  private readonly timeScale: number;
  private readonly noiseScale: number;
  private readonly stepSize: number;
  private readonly bufferSpace: number;
  private readonly noiseInternalScale: number;

  constructor(flowSide: Side, timeScale: number, noiseScale: number, seed: string) {
    this.flowSide = flowSide;
    this.timeScale = timeScale;
    this.noiseScale = noiseScale;
    this.noiseGen = new SimplexNoise(seed);
    //
    this.stepSize = 40;
    this.bufferSpace = this.stepSize * 1.5;
    this.noiseInternalScale = 0.08;
  }

  renderToPathString = (time: number, offAxisSize: number, onAxisSize: number): string => {
    const nOffsets = (onAxisSize + 2 * this.bufferSpace) / this.stepSize;
    const offsets: number[] = [];
    for (let i = 0; i < nOffsets; i++) {
      offsets.push(this.noiseGen.noise2D(time * this.timeScale, i * this.noiseInternalScale) * this.noiseScale);
    }

    const controlPoints: AxialPoint[] = [];
    controlPoints.push({onAxis: -this.bufferSpace, offAxis: offAxisSize});
    offsets.forEach(offset => {
      const lastPoint = controlPoints[controlPoints.length - 1];
      controlPoints.push({
        onAxis: lastPoint.onAxis + this.stepSize,
        offAxis: offAxisSize + offset,
      });
    });

    const curves: Curve[] = [];
    for (let i = 0; i < controlPoints.length - 2; i++) {
      const anchorStart = avg(controlPoints[i], controlPoints[i + 1]);
      const controlPoint = controlPoints[i + 1];
      const anchorEnd = avg(controlPoints[i + 1], controlPoints[i + 2]);
      curves.push({
        anchorStart,
        controlPoint,
        anchorEnd,
      });
    }

    let getX: (p: AxialPoint) => number;
    let getY: (p: AxialPoint) => number;

    switch (this.flowSide) {
      case Side.RIGHT:
      case Side.LEFT:
        getX = (p) => p.offAxis;
        getY = (p) => p.onAxis;
        break;
      case Side.BOTTOM:
      case Side.TOP:
        getX = (p) => p.onAxis;
        getY = (p) => p.offAxis;
        break;
    }

    let path = "";
    if (curves.length > 0) {
      path = `M${getX(curves[0].anchorStart)},${getY(curves[0].anchorStart)}`;
      curves.forEach(curve => {
        path += ` C${getX(curve.controlPoint)},${getY(curve.controlPoint)}`
        path += ` ${getX(curve.controlPoint)},${getY(curve.controlPoint)}`
        path += ` ${getX(curve.anchorEnd)},${getY(curve.anchorEnd)}`
      });
      //
      const lastCurve = curves[curves.length - 1];
      const end1: AxialPoint = {onAxis: lastCurve.anchorEnd.onAxis, offAxis: 0};
      const end2: AxialPoint = {onAxis: curves[0].anchorStart.onAxis, offAxis: 0};
      //
      switch (this.flowSide) {
        case Side.RIGHT:
          path += ` L${getX(end1)},${getY(end1)}`
          path += ` L${getX(end2)},${getY(end2)}`
          break;
        case Side.TOP:
          path += ` L${getX(end1)},${getY(end1)}`
          path += ` L${getX(end2)},${getY(end2)}`
          break;
        case Side.BOTTOM:
          path += ` L${getX(end1)},${-1 * getY(end1)}`
          path += ` L${getX(end2)},${-1 * getY(end2)}`
          break;
        case Side.LEFT:
          path += ` L${-1 * getX(end1)},${getY(end1)}`
          path += ` L${-1 * getX(end2)},${getY(end2)}`
          break;
      }

    }

    return path;
  }
}
