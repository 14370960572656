import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ContentContainer } from "../../components/ContentContainer";

const TitleDetail = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  min-height: 500px;
`;

const TitleBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
`;

const BackButton = styled.img.attrs({src: "/arrow-back.svg", alt: "Back"})`
  margin-top: 5px;
  opacity: 50%;
  transition: opacity .1s ease-in-out;
  
  :hover {
    opacity: 100%;
  }
`;

const Title = styled.div`
  font-size: 2rem;
  font-family: Lato, sans-serif;
`;

const Detail = styled.div`
  font-family: Average Sans, sans-serif;
  font-size: 1.1rem;
  line-height: 1.4;
  text-indent: 25px;
`;

export const ProjectDetail: FunctionComponent<{name: string}> = (props) => {
  return (
    <ContentContainer>
      <TitleDetail>
        <TitleBar>
          <Link to={"/projects"}><BackButton /></Link>
          <Title>{props.name}</Title>
        </TitleBar>
        <Detail>
          {props.children}
        </Detail>
      </TitleDetail>
    </ContentContainer>
  );
};
