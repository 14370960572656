import React, { FunctionComponent } from "react";
import styled, { keyframes } from "styled-components";

const FallIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: ${FallIn} 0.5s ease-out forwards;
  animation-delay: 0.1s;
`;

const InnerContainer = styled.div`
  overflow-y: auto;

  @media (max-width: 1000px) {
    width: 90%;
    padding: 15% 15% 30%;
  }
  @media (min-width: 1000px) and (max-width: 1500px) {
    width: 70%;
    padding: 10% 15%;
  }
  @media (min-width: 1500px) {
    width: 50%;
    padding: 10% 25%;
  }
  
`;

export const ContentContainer: FunctionComponent<{className?: string}> = (props) => (
  <OuterContainer>
    <InnerContainer className={props.className}>
      {props.children}
    </InnerContainer>
  </OuterContainer>
);
