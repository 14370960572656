import React from "react";
import { Link } from "react-router-dom";

export const ProjectReactTeaching = () => {
  return (
    <>
      <h2>Introduction</h2>
      <p>
        In Autumn 2018, when I took CSE 331 (Software Design & Implementation) myself as a student, a new final
        assignment series was being piloted for the first time: creating the user interface for the final
        project using web technologies. The course has a five-assignment sequence that spans the entire last
        half of the quarter, and has students building each part of a larger project that will show a map of
        campus to a user and find the shortest walking route between any two buildings. The last two assignments
        introduce a user interface technology and then have students build the final frontend for their project
        using that technology.
      </p>
      <p>
        Previous quarters had used either Java Swing or Android for the frontend, each of which had their
        pedagogical issues. Swing was outdated and would be less helpful for students once they actually entered
        the industry, and Android was too much of a black-box that students wouldn't be able to understand or
        debug without much more instruction than we had time to provide. The decision was made to switch to a
        web interface, using Spring Boot to make the preexisting Java application available as an API to a
        React.js frontend that would also be written by students.
      </p>
      <p>
        The first quarter this was offered was rough, to say the least. React was barely taught to students, and
        instead they were deferred to the React documentation which, while well-written, makes little sense to
        students who have been writing only Java for their entire coding careers. Spring Boot was (in my
        opinion) a worse black box than Android, with annotations and singleton dependency injection happening
        "magically" without the students understanding any of the plumbing behind the scenes. It also didn't
        make much sense for the use-case: Spring was an industrial-sized sized steamroller doing the work of a
        paperweight, and it really felt like that when working with it.
      </p>
      <h2>Backend Wrangling</h2>
      <p>
        In Winter 2019, the following quarter, a different instructor taught the course and did his standard
        Android-based curriculum. When I joined the course staff in Spring 2019, I quickly made it my personal
        mission to fix these last three weeks of the course. My first task was to find a suitable replacement
        for Spring. We needed nothing more than a few HTTP endpoints capable of responding to simple GET
        requests. At worst, the most complicated request had a few query parameters, so the feature richness of
        my solution was of secondary importance. Above all else: it needed to <em>make sense to the
        students</em>.
      </p>
      <p>
        The answer to all of these requirements was the Spark Java framework. (Not Apache Spark, don't worry.)
        Spark offered a simple declarative way to create a REST API without students having to worry about port
        or socket configurations, dependency injections, or annotation-based metaprogramming (I'm looking at
        you, Spring). Students could write the entirety of the backend for the web-interface portion of the
        project with about 20 lines of Java code, which would allow them to really focus on understanding the
        new web technologies being presented to them.
      </p>
      <h2>Harnessing React</h2>
      <p>
        Once I had a proof-of-concept version of the assignment using Spark Java instead of Spring Boot, we
        turned toward making the React portion of the assignment understandable for students. We assume no
        previous language knowledge beyond some introductory Java, as the only prerequisites for the course are
        the 142/143 intro courses that teach Java to new students. Therefore, the first step was to teach some
        basic Javascript. The professor at the time took the lead in creating a two-lecture series on ES6
        basics, including a fairly thorough history on how ES6 "classes" work, as well as a basic overview of
        the constructs of the language expressed as a diff from Java.
      </p>
      <p>
        As we ended up creating a lot of this overhauled content on-the-fly, we didn't have the opportunity to
        design the lecture calendar to really fit the content. As a result we had very little time to actually
        teach React. Since the instructor for the course knew very little React at the time, it fell to a few of
        the TAs to create the teaching content for React. We only really had about 90 minutes all-told to teach
        all the React theory necessary for the students to be able to create a single-page webapp. These 90
        minutes were broken into two "section" periods, on the Thursdays of two weeks in a row, and were taught
        to groups of 20-30 students by 9 TAs (including myself) in 9 different class periods. (There were over
        200 students in the course overall.)
      </p>
      <h2>Cheating a Little</h2>
      <p>
        It became necessary to tell a simpler story, and we had to bend some React rules to create that story.
        Some of the trade-offs that we made were:
      </p>
      <ul>
        <li>Only creating class components and ignoring the existence of functional components.</li>
        <li>Relying on a canvas element and (likely) an overuse of state to simplify the map-drawing code.</li>
        <li>Making fairly heavy use of component lifecycle methods.</li>
      </ul>
      <p>
        While it may seem as though we ditched many important React concepts, the primary goal of teaching React
        was (perhaps unintuitively), not to teach React. Instead, we wanted to use React to teach the primary
        concepts of user interface development at a lower level. Some of the concepts that were part of the core
        curriculum goals of the course are described below, along with their React-oriented counterparts:
      </p>
      <ul>
        <li>
          A (very) basic introduction to UI asynchrony. &mdash; We discussed in-depth the update lifecycle,
          the asynchronous nature of setState, and the possible resultant race conditions. We made
          asynchronous server requests using fetch.
        </li>
        <li>
          A basic introduction to the FP paradigm of callbacks and listeners. &mdash; Callbacks are used
          throughout the application the students build, both as traditional "listeners" like onClick and
          onChange props for UI elements, and as student-built callbacks from child components into parent
          components when parent-owned state needs to be updated.
        </li>
        <li>
          Working within a framework bigger than yourself. &mdash; We want students to take a step toward
          working with large preexisting codebases that have established ways of doing things. For example, in
          Java Swing a component should never attempt to call its own paintComponent method, instead allowing
          the UI thread to call it as-necessary to update the UI. In React, this manifests very similarly -
          components should never call their own lifecycle methods (or render), or attempt to bypass React's
          Virtual DOM by calling builtins like document.getElementById.
        </li>
      </ul>
      <p>
        Additionally, I'll argue that the things that we did forgo aren't <em>particularly</em> bad. React
        focuses heavily on components as pure functions, and while we threw a lot of dust at that purity, the
        students will still be walking away with a strong understanding of some incredibly important core React
        concepts, including:
      </p>
      <ul>
        <li>The unidirectional data-flow model.</li>
        <li>Use of callbacks to request state updates from children.</li>
        <li>Creating a 'single source of truth', such as by using controlled UI components.</li>
        <li>Encouraging code reuse through careful segmentation of components.</li>
      </ul>
      <p>
        While these students won't yet ready to walk into an internship at Facebook and immediately begin
        modifying facebook.com, they'll be more than comfortable being asked to learn React with their fellow
        interns, and will probably help their peers out during the early lesson on the core concepts.
      </p>
      <h2>Actually Teaching the Thing</h2>
      <p>
        The current infrastructure lead (and effectively my mentor for the quarter) was the other primary person
        involved in creating the new React content. After discussion with a few of the TAs and the professor for
        the course, he took charge in creating a series of examples that walked students through what we
        believed was the simplest way of introducing the concepts of components, props, state, callbacks, user
        input listeners, and canvases. This was no small task, and he pulled it off incredibly well.
      </p>
      <p>
        As the person who chose and created the proof of concept for the Spark Java server portion of the
        assignment, I was responsible for creating the lecture content for the second 45-minute section, as well
        as the official assignment specification that would be distributed to students. This content included
        most of our discussion of asynchrony through promises, and had two main topics: creating the routes with
        the Spark Java server (essentially, how to write those 20-ish lines of code), and consuming those new
        endpoints using fetch and ES6 promises.
      </p>
      <p>
        Between these two sections and some brief additional materials posted on the course website, we had
        exhausted our time to teach the material needed for the assignments. Our discussion of the material
        could best be described as very broad but very shallow. We didn't have the time or forethought to focus
        on the basic concepts for enough time to be fully understood by the students before we had to move on to
        more advanced usage. For example - we were discussing the race condition issues with setState within an
        hour of introducing the concept of components, which was also many students' first contact
        object-oriented programming in ES6.
      </p>
      <p>
        We did the best that we could given the situation, but we all knew (especially the students) that we
        didn't do as well as we would've liked. The students were ultimately successful in the sense that many
        of them completed the assignment and had a working campus map application to show off to their friends
        and roommates. However, the amount of repeated help with basic concepts during office hours and the
        amount of late nights and coding-by-accident was unacceptably high relative to the other assignments. Of
        course, I don't blame the students for this; the changes that we made to the course can easily seem
        arbitrary and frustrating, especially when the content isn't taught to the level of depth that is
        necessary for reasonable success.
      </p>
      <p>
        At this point in the quarter, we had already determined that I would be returning to the same course for
        summer quarter with a full-time appointment. The course was going to be taught by the same instructor,
        meaning we were both on the same page from the very beginning about my personal goals through the
        quarter. I took on a very non-standard role as a TA, with my two primary goals being: (1) redesigning
        and overhauling the backend software for the course, and (2) improving the React and general web
        technologies section of the course to create a reasonable (and consistent) level of difficulty for the
        students. My adventures with goal (1) are described in <Link to="/projects/courseware">my project page on
        the 331 infrastructure development,</Link> while the story about (2) continues below.
      </p>
      <h1>Summer Quarter</h1>
      <p>
        Summer quarter afforded us the option to revise the React teaching further. It was clear that we needed
        to dedicate a significant amount of lecture time to teaching the core concepts of React. I had a meeting
        early on with the professor for the course where we attempted to differentiate between the concepts that
        were absolutely necessary to teach during lecture, and those that the students could learn more
        independently. The lecture concepts were required to have two characteristics: (a) they are
        basic/fundamental and really deserve an in-person explanation, and (b) they are concepts likely to be
        encountered by students during their React.js assignments. Creating a list that satisfies (b) was fairly
        easy &mdash; at this point, I had solved the assignment myself a number of times in different ways, and
        I was familiar with what was necessary for me to achieve that solution. Creating a list that satisfies
        (a) went against my instincts: if I had my way, I'd just teach everything to make sure people understood
        it fully. That, of course, was impractical. We settled on a middle ground: we'd use our available
        lecture time to cover the core concepts of React, and I'd create a handout to be released online
        covering additional details that were useful/necessary for success, but could be learned more
        independently than some of the core ideas.
      </p>
      <p>
        During that meeting, we decided to split the content between the two of us in a similar way to the split
        during Spring quarter: the professor would teach about 1.5 lectures worth of content on basic JS,
        expressed as a diff from Java with a focus on practical usage for our assignments, and I would teach
        about 2.5 lectures worth of content expanding that knowledge to include React-specific ideas and
        technologies necessary for success with the assignments. The instructor for that course will be the
        first to tell you that his lack of familiarity with React provided a functional benefit to our ability
        to design the course materials well: our meeting took the form of a pseudo-lecture where I taught him
        the material in an order that made sense to me, and he'd stop me as I inadvertently skipped things or
        confused him so we could come up with a version of the lecture that avoids that confusion.
      </p>
      <p>
        I walked away from that meeting with a large notes document outlining the content and order of material
        for the lectures, along with a laundry list of things that I wanted to teach in some way, but would
        probably have to be relegated to the handout. I spent the next weeks revising the two assignments to
        provide more guidance with the knowledge of what students would and wouldn't be directly taught, and
        creating powerpoint slides and a large set of examples to be used during lectures. I also wrote <a
        href="https://courses.cs.washington.edu/courses/cse331/19su/concepts/react-tips.html"
        rel="noopener noreferrer" target="_blank">the handout</a> that would be provided to students for their
        reference while working on the assignments. One of the most fundamental changes we made was reversing
        our discussion of the two main data-types in React: while previous quarters had discussed props before
        state, we actually chose to introduce state before props. While, at a basic level, props are a simpler
        concept with their immutable, unidirectional data flow, focusing on state allowed us to ignore the
        complexities of component composition (say <em>that</em> three times fast) until much later in the
        lectures.
      </p>
      <p>
        I also made a number of smaller changes to the assignment requirements or expected solution that allowed
        us to narrow or focus and rely more on preexisting resources for teaching students. Some of these
        changes include:
      </p>
      <ul>
        <li>
          Removing the requirement to using the MaterialUI React library for UI elements. It's my opinion that
          the MaterialUI documentation is horrible &mdash; it's written more as a "cheat sheet" for those who
          fully understand its inner workings and just need the occasional reminder, instead of documentation
          designed to inform a new user (especially a student who is new to web technologies as a whole) how
          to use it effectively. We chose to, instead,+ support and encourage the use of 'vanilla' HTML5 form
          elements and UI components.
        </li>
        <li>
          Teaching asynchronous calls using the ES8 async/await syntax instead of the Promise.then() chaining.
          This allowed us to sidestep a few of the more difficult parts of using promises, especially among
          students who are only <em>just</em> being introduced to higher-order functional programming. The
          amount of time spent teaching students to make a fetch() request and retrieve the body data from the
          response was easily cut in half, if not shorter.
        </li>
        <li>
          Moving one of the smaller required features of the final assignment to being an extra credit
          portion, instead of being required. This allowed the server code to be slightly simpler and rely
          more on students' preexisting code from previous assignments, which allowed students to focus more
          on their frontend difficulties.
        </li>
      </ul>
      <h2>The New Plan</h2>
      <p>
        Our new plan for the course took shape over these few weeks leading up to this lecture series, and the
        teaching order ended up looking something like the following:
      </p>
      <ul>
        <li>
          Javascript Basics (JS history, primitives, operators, if/else, loops, globals, let/const, objects,
          functions, and arrays)
        </li>
        <li>
          Some Advanced JS (First-class functions, arrow functions, the basic ideas of prototypes, ES6
          classes)
        </li>
        <li>
          A brief/formal introduction to HTML tags/attributes
        </li>
        <li>
          The canvas tag and using a 2D graphics context
        </li>
        <li>
          Some React theory (The component model, JS-describing-markup instead of JS-extending-markup)
        </li>
        <li>
          The basic React boilerplate (ReactDOM.render, practical usage of create-react-app, the "Hello World"
          component, JSX & its translation)
        </li>
        <li>
          Rendering a canvas in a React component (Controlling the drawing on the canvas using state, basic
          lifecycle methods)
        </li>
        <li>
          Buttons & listeners modifying state
        </li>
        <li>
          Code decomposition into components, communicating data using props
        </li>
        <li>
          User-defined callbacks and creating custom "controlled" components
        </li>
        <li>
          JSON: What it means, why it's useful
        </li>
        <li>
          Defining application endpoints using Spark Java
        </li>
        <li>
          Consuming those endpoints using asynchronous requests in JS (fetch, async/await)
        </li>
      </ul>
      <p>
        This plan worked fairly well &mdash; there was a significant drop in questions during office hours
        related to core React concepts, and most of the student could spend their time focusing on the
        higher-level design or fixing weird bugs, not just on understanding the flow of data between the
        components.
      </p>
      <p>
        By the end of the quarter, we had a reasonable set of course materials for teaching React to students
        who hadn't written anything other than Java code for their whole lives. I'm staying on the course for
        at least a few more quarters, and we plan to continue to clean up the material and refine it based on
        student feedback, but most of the heavy lifting for this course segment is now done. I plan on working
        closely with both past and current students to modify and improve the materials as we determine exactly
        what the pedagogical sticking points are with these new concepts.
      </p>
    </>
  );
};
